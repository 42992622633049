<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!list"
        elevation="0"
        v-on="on"
        block
        height="30px"
        :disabled="disabled"
        v-text="$t('edit', { name: '' })"
        @click="val = value == 1"
      />
    </template>
    <v-card>
      <v-card-title>Servicios propios</v-card-title>

      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" v-if="$store.getters['auth/isStudio']">
            {{ $t("own_service_studio_1") }}
            <br />
            <br />
            {{ $t("own_service_studio_2") }}
          </v-col>
          <v-col cols="12" v-else>
            Cuando se crean las citas en el estudio correspondiente, ¿quiero
            poder seleccionar mis propios servicios? *siempre que el estudio lo
            permita.
          </v-col>
          <v-col cols="12" md="12" class="mt-5">
            <v-select
              outlined
              dense
              :items="yesNo"
              v-model="val"
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          @click="
            dialog = false;
            files = [];
          "
          style="height: 25px; width: 100px"
          elevation="0"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          @click="save"
          style="
            width: 100px !important;
            color: #363533;
            height: 25px;
            padding-top: 10px !important;
          "
          elevation="0"
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "OwnService",
  props: ["list", "disabled", "tattooer_id", "studio_id", "value", "_key"],
  data() {
    return { dialog: false, val: false };
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
  },
  methods: {
    setDate() {
      this.date = this.ts.from;
    },
    ...mapActions("tattooers", ["ownService"]),
    save() {
      let formData = {
        key: this._key,
        value: this.val,
      };
      this.ownService({
        studio_id: this.studio_id,
        tattooer_id: this.tattooer_id,
        formData,
      }).then((response) => {
        this.dialog = false;
        this.$alert(this.$t("save_ok"));
        this.$emit("update");
      });
    },
  },
  computed: {
    ...mapGetters("app", ["yesNo"]),
  },
};
</script>
